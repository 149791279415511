<template>
  <page-template
    :img="{
      src: require('@/assets/o-nas-1050x630.jpg'),
      lazy: require('@/assets/o-nas-105x63.jpg'),
    }"
    title="O nas"
  >
  <h3>
    Przedszkole jest otwarte od poniedziałku do piątku w godz. 7.30 – 17.00
  </h3>
  <h2>
    PLAN DNIA
  </h2>
  <ul>
    <li><b>7.30 - 9.00</b> Przyprowadzanie dzieci do przedszkola, swobodna zabawa</li>
    <li><b>8.30 - 11.30 Śniadanie</b> – dzieci samodzielnie przygotowują sobie śniadanie, które jest dostępne w formie szwedzkiego stołu. </li>
    <li><b>9.00 - 9.30 Spotkanie w kręgu</b> – czas na rozmowę, podzielenie się przez dzieci ważnymi dla nich sprawami, omówienie dnia, wprowadzenie nowego tematu, zabawy integracyjne, piosenki.</li>
    <li><b>9.30 - 12.00 Praca własna z materiałami Montessori</b> – pracę w metodzie Montessori należy rozumieć jako swobodną aktywność dziecka. W tym czasie dzieci mogą poruszać się po sali. Samodzielnie decydują o wyborze materiału, z którym chcą pracować, miejscu i czasie jaki temu poświęcą. Nauczyciel pomaga w wyborze dzieciom, które nie potrafią jeszcze same zdecydować i prezentuje dzieciom materiał. W czasie “pracy własnej” dzieci zdobywają konkretną wiedzę i umiejętności (np. manualne, matematyczne, językowe), jak również uczą się samodzielnie decydować, planować, współpracować z innymi, przestrzegać reguł panujących w grupie. <b>Język angielski i zajęcia umuzykalniąjące.</b></li>
    <li><b>12.00 Obiad - zupa.</b> Idea Montessori zakłada rozwój samodzielności dzieci, również podczas posiłków. Dzieci samodzielnie nakrywają do stolików i sprzątają po sobie. W naszym przedszkolu dzieci nie są zmuszane do jedzenia, ani dokarmiane. Mogą decydować kiedy i ile chcą zjeść. </li>
    <li><b>12.30 - 14.00 Wyjście na plac zabaw lub spacer po okolicy.</b> Dzieci codziennie wychodzą na dwór, niezależnie od pogody. Ubierają się w miarę możliwości samodzielnie, a nauczyciele zwracają uwagę by były ubrane odpowiednio do pogody. Dzięki zabawie na  świeżym powietrzu dzieci są dotlenione, mogą wyładować nadmiar energii, rozwijają sprawność fizyczną.</li>
    <li><b>14.00 - 14.30 Obiad - drugie danie</b></li>
    <li><b>14.30 - 15.30 Relaks</b> (czytanie bajek, słuchanie muzyki, dla chętnych drzemka)</li>
    <li><b>15.30 - 16.30 Praca własna, zajęcia dodatkowe i podwieczorek</b></li>
    <li><b>16.30 - 17.00</b> Swobodna zabawa, odbieranie dzieci z przedszkola.</li>
  </ul>
  <p>
    Podstawa programowa MEN realizowana jest poprzez pracę z materiałem rozwojowym Montessori, w czasie pracy własnej oraz zajęć w kręgu.
  </p>
  <p>
    Prezentowany plan dnia ma charakter ramowy.
  </p>
  <h2>
    WYŻYWIENIE
  </h2>
  <p>
    W naszym przedszkolu dbamy nie tylko o jakość edukacji, ale również o zdrowie naszych dzieci. Ważne jest dla nas by dzieci jadły zdrowe, ekologiczne i zbilansowane posiłki. Śniadania, obiady i podwieczorki dostarcza nam firma cateringowa “Weranda Smaków”. Menu uwzględnia również diety: bezglutenową, bezmleczną, bezjajeczną, wegetariańską. 
  </p>
  <h2>
    PROGRAM 
  </h2>
  <p>
    Główne działy edukacji Montessori obejmują:
  <ol>
    <li><b>Praktyczne ćwiczenia życia codziennego</b> – z tymi ćwiczeniami dzieci pracują na początku. Ich celem  jest przede wszystkim uczenie dzieci samodzielności i niezależności. Ćwiczenia te budują także poczucie własnej wartości, ćwiczą koncentrację, rozwijają koordynację wzrokowo-ruchową, sprawność manualną, uczą porządku i szacunku do otoczenia.</li>
    <li><b>Edukacja sensoryczna</b> – zdaniem M.Montessori doskonalenie wszystkich zmysłów powinno zajmować pierwsze miejsce w edukacji dzieci. Okres przedszkolny, to czas gdy dziecko uczy się aktywnie- poprzez działanie i operowanie konkretami. Doznania zmysłowe pozwalają mu na doświadczanie i poznawanie otaczającego je świata.</li>
    <li><b>Edukacja matematyczna</b> –  jest więc czymś więcej niż liczeniem- jest sposobem porządkowania świata i rozwiązywania problemów. M.Montessori od Pascala zaczerpnęła pojęcie „umysłu matematycznego”. Dziecko poznając świat porządkuje i kategoryzuje swoje doświadczenia, co stanowi początek matematycznego myślenia. Dzieci uczą się poprzez doświadczenia zmysłowe, przechodzą od konkretu do abstrakcji w sposób naturalny i płynny.</li>
    <li><b>Edukacja językowa</b> – prowadzi do przyswojenia przez dziecko umiejętności wyrażania swoich myśli oraz komunikacji z otoczeniem. Służą temu ćwiczenia z zakresu wzbogacania słownictwa, pisania i czytania ze zrozumieniem.</li>
    <li><b>Edukacja kulturowa </b> –  opisuje i tłumaczy dziecku świat, który je otacza i na który ma ono znaczący wpływ. Edukacja w ramach kultury ma charakter zintegrowany, przeplatają się w niej elementy takich nauk jak geografia, astronomia, historia i biologia (botanika i zoologia).</li>
  </ol>
  </p>
  </page-template>
</template>
<script>
import PageTemplate from '../components/PageTemplate.vue';
export default {
  components: { PageTemplate }
}
</script>
