<template>
  <div class="main-content">
    <h1 class="text-center mb-7 site-title">
        <slot name="title">{{title}}</slot>
    </h1>
    <v-row v-if="img" justify="center">
      <v-img
        :src="img.src"
        :lazy-src="img.lazy"
        max-width="100%"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-row>
    <v-row v-if="img && img.caption" align="center">
      <v-col class="text-center img-caption">
        {{img.caption}}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
    props: ['img', 'title'],
}
</script>

<style scoped>
.site-title {
  font-size: min(9vw, 55px);
}
</style>